<template>
  <validation-observer ref="new-policy-form" v-slot="{ invalid }">
    <b-form @submit.prevent="handleSubmitClick">
      <b-form-group label="Name">
        <template slot="label"> Name <span class="text-danger">*</span> </template>
        <validation-provider
          #default="{ errors }"
          name="Name"
          rules="required|min:4|max:120"
        >
          <b-form-input
            id="title"
            v-model="formData.title"
            :state="errors.length > 0 ? false : null"
            name="Name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Description">
        <template slot="label">Description <span class="text-danger">*</span> </template>
        <validation-provider #default="{ errors }" name="Description" rules="required">
          <b-form-textarea
            rows="3"
            max-rows="5"
            id="description"
            v-model="formData.description"
            :state="errors.length > 0 ? false : null"
            name="Description"
          >
          </b-form-textarea>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div class="d-flex justify-content-center w-100">
        <b-button
          type="submit"
          variant="success"
          class="mt-1"
          :disabled="invalid || showSpinner"
        >
          <span class="align-middle" v-if="showSpinner">
            <b-spinner small class="mr-50" variant="light" />
            <span>Please Wait..</span></span
          >
          <span class="align-middle" v-else>
            <feather-icon icon="CheckIcon" class="mr-50" />
            <span>Create</span></span
          >
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BButton,
  BOverlay,
  BBadge,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import PolicyMixins from "../../../mixins/PolicyMixins";

export default {
  mixins: [ResponseMixins, PolicyMixins],
  props: {
    reloadParent: {
      type: Function,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BOverlay,
    BBadge,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    BSpinner,
    required,
  },

  data() {
    return {
      showSpinner: false,
      formData: {
        title: null,
        description: null,
      },
    };
  },

  methods: {
    handleSubmitClick() {
      this.addPolicyAndSetData(this.formData);
    },

    addPolicyAndSetData(data) {
      this.showSpinner = true;
      this.addPolicy(data)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
  },
};
</script>

<style></style>
