<template>
  <div class="w-100 h-auto">
    <b-modal ref="new-policy-modal" hide-footer title="Create new policy" size="lg">
      <add-policy-form :closeAction="hideNewPolicyModal" :reloadParent="load"
    /></b-modal>
    <b-modal ref="edit-policy-modal" hide-footer title="Edit policy" size="lg">
      <edit-policy-form
        :id="selectedPolicyId"
        :closeAction="hideEditPolicyModal"
        :reloadParent="load"
      />
    </b-modal>
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h3 class="mb-25 font-weight-bolder">Policies</h3>
            <p class="mb-0">Manage all your policies.</p>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end">
            <b-button
              size="sm"
              variant="success"
              class="mr-1"
              @click="handleNewPolicyClick"
              ><feather-icon icon="PlusIcon" class="mr-50" />New Policy</b-button
            >
            <b-button size="sm" variant="success" @click="handleImportPolicyClick"
              ><feather-icon icon="DownloadCloudIcon" class="mr-50" />Import from
              OneCSF</b-button
            >
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div class="position-relative table-responsive mb-0">
          <table role="table" class="table table-hover">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Description</th>
                <th role="columnheader" scope="col">Status</th>
                <th role="columnheader" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="policies && policies.length > 0">
                <tr role="row" v-for="(policy, index) in policies" :key="policy._id">
                  <th scope="row">
                    {{ index + 1 + (filters.page - 1) * 10 }}
                  </th>

                  <td role="cell" style="max-width: 25vw">
                    <p class="font-weight-bold mb-0">{{ policy.title }}</p>
                    <b-badge
                      variant="light-info"
                      v-if="policy.master_policy_id && policy.master_policy_id != null"
                      class="mt-25"
                      >Imported</b-badge
                    >
                  </td>
                  <td role="cell" style="max-width: 20vw">
                    {{ policy.description }}
                  </td>

                  <td role="cell">
                    <b-badge
                      :variant="mapStatusToBadgeVariant(parseInt(policy.status))"
                      >{{ mapStatusToText(parseInt(policy.status)) }}</b-badge
                    >
                  </td>

                  <td role="cell">
                    <b-dropdown size="sm" text="Select" variant="outline-primary">
                      <b-dropdown-item
                        @click.stop="
                          () => {
                            handleDeletePolicyClick(policy._id);
                          }
                        "
                        ><span class="align-middle">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span class="align-middle">Delete</span>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click.stop="
                          () => {
                            handleEditPolicyClick(policy._id);
                          }
                        "
                        ><span class="align-middle">
                          <feather-icon icon="EditIcon" class="mr-50" />
                          <span class="align-middle">Edit</span>
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Policies Empty">
                  <template #content>
                    <p class="text-center">
                      It seems there are no policies at the moment. You can create one by
                      clicking the New Policy Button.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
        <div class="card-body" v-if="policies && policies.length > 0">
          <div class="demo-spacing-0 d-flex align-items-center justify-content-between">
            <p class="align-middle mb-0">
              Showing {{ from }} to {{ to }} of {{ total }} entries
            </p>

            <b-pagination
              v-model="filters.page"
              :total-rows="total"
              :per-page="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BPagination,
  BAvatar,
  BBadge,
  VBTooltip,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BDropdownDivider,
  BModal,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import PolicyMixins from "@/mixins/PolicyMixins";
import AddPolicyForm from "./components/AddPolicyForm.vue";
import EditPolicyForm from "./components/EditPolicyForm.vue";
import EmptyTableSection from "../../components/EmptyTableSection.vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BPagination,
    BAvatar,
    BBadge,
    FeatherIcon,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BDropdownDivider,
    BModal,
    BInputGroupAppend,
    BSpinner,
    AddPolicyForm,
    EditPolicyForm,
    EmptyTableSection,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [ResponseMixins, PolicyMixins],
  data() {
    return {
      total: 0,
      from: 0,
      to: 0,
      policies: [],
      filters: {
        search: null,
        page: 1,
        include_inactive: 1,
      },
      selectedPolicyId: null,
      showOverlay: false,
    };
  },

  watch: {
    filters: {
      handler: function (newValues) {
        this.getPoliciesAndSetData(newValues);
      },
      deep: true,
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getPoliciesAndSetData(this.filters);
    },

    handleImportPolicyClick() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to import policies from OneCSF.", {
          title: "Warning",
          size: "sm",
          headerBgVariant: "light-warning",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.importPoliciesFromMasterAndSetData();
          }
        });
    },

    importPoliciesFromMasterAndSetData() {
      this.showOverlay = true;
      this.importPoliciesFromMaster()
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleDeletePolicyClick(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this Policy permanently", {
          title: "Warning",
          size: "sm",
          headerBgVariant: "light-warning",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletePolicyAndSetData(id);
          }
        });
    },

    deletePolicyAndSetData(id) {
      this.showOverlay = true;
      this.deletePolicy(id)
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
          this.load();
        });
    },

    handleEditPolicyClick(id) {
      this.selectedPolicyId = id;
      this.showEditPolicyModal();
    },

    showEditPolicyModal() {
      this.$refs["edit-policy-modal"].show();
    },
    hideEditPolicyModal() {
      this.$refs["edit-policy-modal"].hide();
    },

    showNewPolicyModal() {
      this.$refs["new-policy-modal"].show();
    },
    hideNewPolicyModal() {
      this.$refs["new-policy-modal"].hide();
    },

    handleNewPolicyClick() {
      this.showNewPolicyModal();
    },

    getPoliciesAndSetData(params) {
      this.showOverlay = true;
      this.getPolicies(params)
        .then((res) => {
          console.log("Policies", res);
          this.policies = res.data.data.data;
          this.from = res.data.data.from;
          this.to = res.data.data.to;
          this.total = res.data.data.total;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    mapStatusToText(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Inactive";
        default:
          return "Unknown";
      }
    },

    mapStatusToBadgeVariant(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "danger";
        default:
          return "secondary";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table th {
  text-transform: none;
}

.filters_section {
  background-color: #f2f0f7;
}

.dark-layout {
  .filters_section {
    background-color: #161d31;
  }
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
